import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Securing the posts to the ground"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 2. Securing the posts to the ground`}</h1>
    <p>{`There are 3 different methods for you to choose from:`}</p>
    <ol>
      <li parentName="ol">{`Original Spiked Fixing Posts`}</li>
      <li parentName="ol">{`Bolt-Down Fixing Posts`}</li>
      <li parentName="ol">{`Concrete-In Fixing Posts`}</li>
    </ol>
    <p>{`When ordering, you will be prompted as to which method you require`}</p>
    <h2>{`Post supports – Installation instructions:`}</h2>
    <h4>{`1) Original - 'Spiked' version`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-2-securing-the-posts-to-the-ground/fixing-spike.jpg" alt="A hand-drawn schematic of a spike fixing, demonstrating that the fixings should ideally be placed using a sledgehammer and a spirit level" />
  <span className="text-metadata">Fig 12</span>
    </div>
    <p>{`These have been designed to allow you to install your posts securely in the ground in no time and without the hassle of digging holes or buying concrete. Simply, place the tip of the spike over the point in the ground where you want your post position to be and, using a wooden bung or piece of off-cut timber to protect the rim of the spike, take your sledgehammer and gently drive the support into the ground, making sure to check the vertical alignment with a spirit level as you do so, until the box section of the support is level with the ground.`}</p>
    <h4>{`2) 'Bolt - Down' Support`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-2-securing-the-posts-to-the-ground/fixing-bolt.jpg" alt="A hand-drawn schematic of a bolt-down fixing, demonstrating mounting a post directly down into the fixing" />
  <span className="text-metadata">Fig 13</span>
    </div>
    <p>{`As this version of post support is flush fitting, it is ideally used for situations where your structure is to be sited on an existing patio, concrete or decking (ie: hard standing). Using the base as a template, mark the holes on the surface, drill and then secure using the bolts provided ensuring that they are tightly screwed in.`}</p>
    <h4>{`3) 'Concrete - In' Support`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-2-securing-the-posts-to-the-ground/fixing-concrete.jpg" alt="A hand-drawn schematic of a concrete fixing, demonstrating mounting a post directly down into the fixing" />
  <span className="text-metadata">Fig 14</span>
    </div>
    <p>{`These supports are best used in areas where the ground conditions prevent the use of the original ‘Spiked’ post support. So, for example, if your ground is particularly stony or prone to very wet earth it is best to opt for this version. You will need to visit your local builder’s merchant or garden centre and purchase a bag of quick drying ready mixed concrete and we have found that one bag per post fixing is best. Depending on manufacturer, it usually takes about 15 minutes to set and retail at about £3.25 per bag. Once done, dig out a hole (where you want to place the post) approximately 12 ins diameter x 12ins deep in size, and place enough of the prepared quick drying concrete to fill the hole. Next, insert your post support into the centre of the hole and check that it is vertical aligned. Once, the concrete has set the post can be placed in the support.`}</p>
    <p>{`All 3 of the above versions above can be painted if required and will ensure years of maintenance free, secure anchorage. If, for whatever reason, you have cause to change the timber post, you can simply loosen the holding bolts, take it out and replace with another without having to re-dig, drill or concrete in any new post supports. To ensure that the posts are completely straight, it is a good idea to use a spirit level as it is vital to the whole structure that the posts are in line and vertical. Please make sure that the 'notched' side of the post faces inwards as you will be attaching the main beams to this side in the next Step.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      